<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-row>
            <b-col lg="10" offset-lg="1" sm="12">
                <b-overlay :show="loading">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                            <ValidationProvider name="Ads Network" vid="ads_network_id" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Ads Network <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                :options="adsNetWorkList"
                                    id="ads_network_id"
                                    rows="6"
                                    v-model="formData.ads_network_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        <ValidationProvider name="Name" vid="name" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            Title <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="name"
                                rows="6"
                                v-model="formData.name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Thumbnail" vid="thumbnail" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Thumbnail Image<span class="text-danger">*</span>
                            </template>
                            <b-form-file
                                id="thumbnail"
                                rows="6"
                                @change="handleImg"
                                v-model="formData.thumbnail"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-file>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Daily Mission" vid="daily_mission" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Daily Mission<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="daily_mission"
                                rows="6"
                                v-model="formData.daily_mission"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Interstitial Point" vid="interstitial_point" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Interstitial Point<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="interstitial_point"
                                rows="6"
                                v-model="formData.interstitial_point"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Reward Point" vid="reward_point" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Reward Point<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="reward_point"
                                rows="6"
                                v-model="formData.reward_point"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Interstitial Count" vid="interstitial_count" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Interstitial Count<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="interstitial_count"
                                rows="6"
                                v-model="formData.interstitial_count"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Promotion Link" vid="promotion_link" rules="">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Promotion Link
                            </template>
                            <b-form-input
                                id="promotion_link"
                                rows="6"
                                v-model="formData.promotion_link"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Time (sec)" vid="time_sec" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              Time (sec)<span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                id="time_sec"
                                rows="6"
                                v-model="formData.time_sec"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="VPN List" vid="vpn_list" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="formData"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            VPN List <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            :options="vpnList"
                                :multiple="true"
                                id="vpn_list"
                                rows="6"
                                v-model="formData.vpn_list"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        <div>
                            <p class="m-0 mb-2" style="font-weight: bold">  VPN ON OFF  </p>
                            <b-form-checkbox v-model="formData.vpn_on" size="md" name="check-button" switch>
                                <span v-if="formData.vpn_on">ON</span>
                                <span v-else>OF</span>
                            </b-form-checkbox>
                        </div>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Close</b-button>
                            </div>
                        </div>
                        </b-form>
                    </ValidationObserver>
                </b-overlay>
            </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';
export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      if (this.id) {
          this.formData = this.getItem()
      }
  },
  data () {
    return {
      saveBtnName: this.id ? 'Update' : 'Save',
      formData: {
        title: '',
        thumbnail: null,
        vpn_on: true,
        vpn_list: []
      }
    }
  },
  computed: {
      adsNetWorkList () {
        return this.$store.state.commonObj.adsNetWorkList
      },
      loading () {
        return this.$store.state.static.loading
      },
      vpnList () {
        return this.$store.state.commonObj.vpnList
      }
  },
  methods: {
    handleImg( event ){
        this.formData.thumbnail = event.target.files[0];
    },
    getItem () {
        const item = this.$store.state.list.find(item => item.id === parseInt(this.id))
        return JSON.parse(JSON.stringify({...item, vpn_on: item.vpn_on == 1 ? true : false}))
    },
    async register () {
          this.$store.dispatch('mutedLoad', { loading: true, listReload: false })
          let result = null
          let formData = new FormData()

            Object.keys(this.formData).map(key => {
                if(key == 'vpn_on') {
                formData.append(key, this.formData[key] ? 1 : 0)
                } else {
                formData.append(key, this.formData[key])
                }
            })
        formData.append('vpn_list', this.formData.vpn_list)

        if (this.id) {
            formData.append('_method', 'put')
            result = await RestApi.postData(baseUrl, `${'api/quiz-categories/update'}/${this.id}`, formData)
        } else {
            result = await RestApi.postData(baseUrl,'api/quiz-categories/store', formData)
        }
        this.$store.dispatch('mutedLoad', { loading: false, listReload: true })
        this.$store.dispatch('dropdownLoad', { hasDropdownLoaded: false })
        if (result.success) {
            iziToast.success({
                title: 'Success',
                message: result.message
            })
             this.$bvModal.hide('modal-1')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
